import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";
import Layout from "../components/Layout/Layout";
import GraphQLErrorList from "../components/GraphQLErrorList";
import MediaGrid from "../components/MediaGrid";
import {
  mediaType,
  themeType,
  categoryType,
  errorType,
  sieveType,
} from "../types";
import Section from "../components/Section/Section";
import MediaSearch from "../components/MediaSearch";
import SEO from "../components/SEO";

export const query = graphql`
  query MediaPageQuery {
    media: allSanityMedia(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      ...MediaEdge
    }
    themes: allSanityTheme {
      ...ThemeEdge
    }
    categories: allSanityCategory {
      ...CategoryEdge
    }
  }
`;

function RecentMediaSection({ recentMedia }) {
  return (
    <Section heading="Recent Media" background="dark">
      <MediaGrid media={recentMedia} />
    </Section>
  );
}

function MediaPage({ data, errors, location }) {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const mediaNodes = data && data.media && mapEdgesToNodes(data.media);
  const themeNodes = data && data.themes && mapEdgesToNodes(data.themes);
  const categoryNodes =
    data && data.categories && mapEdgesToNodes(data.categories);

  const mediaSieve = {
    filter:
      location && location.state && location.state.filter
        ? { ...location.state.filter }
        : null,
    sort:
      location && location.state && location.state.sort
        ? { ...location.state.sort }
        : null,
  };

  return (
    <Layout>
      <SEO title="Media" />
      <RecentMediaSection recentMedia={mediaNodes.slice(0, 6)} />

      <Section heading="Search Media" id="mediaSearch">
        <MediaSearch
          initialSieve={mediaSieve}
          media={mediaNodes}
          themes={themeNodes}
          categories={categoryNodes}
        />
      </Section>
    </Layout>
  );
}

RecentMediaSection.defaultProps = {
  recentMedia: [],
};

RecentMediaSection.propTypes = {
  recentMedia: PropTypes.arrayOf(mediaType),
};

MediaPage.defaultProps = {
  errors: null,
  location: {
    state: {
      filter: null,
      sort: null,
    },
  },
};

MediaPage.propTypes = {
  data: PropTypes.shape({
    media: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: mediaType,
        })
      ),
    }),
    themes: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: themeType,
        })
      ),
    }),
    categories: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: categoryType,
        })
      ),
    }),
  }).isRequired,
  errors: PropTypes.arrayOf(errorType),
  location: PropTypes.shape({
    state: sieveType,
  }),
};

export default MediaPage;
